
import {computed, defineComponent, onMounted, ref} from "vue";
import store from "@/store";
import {CompanySelect, LoadFilterObjects, LoadPanel} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import Cards from "@/components/base/common/Cards.vue";
import CaseCard from "@/views/case/CaseCard.vue";
import Entities from "@/components/base/common/Entities.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "Actions",
  components: {DateTimeFormat, Entities, CaseCard, Cards, KtDatatable},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('COC Search',
        [
          {link: false, router: '', text: 'COC Search'}
        ]
      )
    })
    const filterObject = ref({status: 'ACTIVE', checkCode: 'CC', clientId:'' })
    const page = computed(() => store.state.CaseModule.watchingCases);
    const tableHeader = ref([
      {
        name: "Case#",
        key: "caseNumber",
        sortable: true,
      },
      {
        name: "Client",
        key: "client",
        sortable: true,
      },
      {
        name: "Policy#",
        key: "policyNumber",
        sortable: true,
      },
      {
        name: "insured",
        key: "insured",
        sortable: true,
      },
      {
        name: "DOB",
        key: "dob",
        sortable: true,
      },
      {
        name: "Case Type",
        key: "caseType",
        sortable: true,
      },
      {
        name: "Case Status",
        key: "caseStatus",
        sortable: true,
      },

      {
        name: "",
        key: "actions",
        sortable: false,
      },
    ]);

    return {
      ...LoadFilterObjects(Actions.LOAD_WATCHING_CASES, filterObject.value, ['client', 'insured', 'policy']),
      filterObject,
      page,
      ...CompanySelect(),
      tableHeader,
      ...LoadPanel(),
    }
  },
  methods:{
    discardEvent() {
      this.filterObject.clientId = ''
      this.updateFilterObject(this.filterObject);
    },
    searchEvent() {
      this.updateFilterObject(this.filterObject)
    }
  }
})
